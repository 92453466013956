.skip-link:focus {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: auto !important;
  height: auto !important;
  padding: 1rem !important;
  background: #fff !important;
  color: #000 !important;
  z-index: 9999 !important;
  text-decoration: none;
  border-radius: 0 0 0.5rem 0;
  outline: 3px solid #007bff;
}

/* High Contrast Mode */
body.high-contrast {
  background-color: #000 !important;
  color: #fff !important;
}

body.high-contrast a {
  color: #00ff00 !important;
}

body.high-contrast button {
  background-color: #fff !important;
  color: #000 !important;
  border: 2px solid #fff !important;
}

body.high-contrast img {
  filter: grayscale(100%) contrast(120%);
}

/* Reduced Motion */
body.reduced-motion *,
body.reduced-motion *::before,
body.reduced-motion *::after {
  animation-duration: 0.001ms !important;
  animation-iteration-count: 1 !important;
  transition-duration: 0.001ms !important;
  scroll-behavior: auto !important;
}
